import React, {useEffect, useState} from 'react';
import {Layout, Space, Row, Col, Tabs, Divider, ConfigProvider, theme, Result} from 'antd';
import {
  MailOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import './App.css';
import logo from './logo-min.svg';
import tech1 from './1.png';
import tech2 from './2.png';
import tech3 from './3.png';
import moment from 'moment';
import {Link, useParams, useRoutes} from "react-router-dom";

const {Header, Content} = Layout;

function NoMatch() {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Извините, страница, которую вы посетили, не существует.. "
        extra={
          <a href={"/"} type="primary">
            На главную
          </a>
        }
      />
    </div>
  );
}

const Home = ({page='front-loader'}) => {

  const [tab, setTab] = useState('front-loader')

  const items = [
    {
      key: 'front-loader',
      label: <Link to={"/front-loader"}><img src={tech1} alt={''} className={'tab-img'}/></Link>,
      children: <Row justify="space-around" align="middle">
        <Col>
          <h2>Погрузчик</h2>
          <p>Услуги фронтального погрузчика. Уборка снега, все виды земельных работ.</p>
          <ul style={{marginBottom: 40, paddingLeft: 15}}>
            {/*<li>Эксплуатационная масса – от 3,9 до 95 т, в среднем до 27 – 50 т</li>*/}
            <li>Объем ковша – 2.1 куб. м</li>
            {/*<li>Грузоподъемность – от единиц тонн до 20 т (в среднем 3 – 7 т)</li>*/}
            {/*<li>Высота подъема ковша – в среднем 2,5 – 3,5 м</li>*/}
            {/*<li>Мощность двигателя – в среднем от 100 до 500 л. с</li>*/}
          </ul>
        </Col>
        <Col>
          <a href={'https://api.whatsapp.com/send?phone=77074393903'}
             className={'button'}><WhatsAppOutlined/> Арендовать</a>
        </Col>
      </Row>,
    },
    {
      key: 'tow-truck-trawl',
      label: <Link to={"/tow-truck-trawl"}><img src={tech2} alt={''} className={'tab-img'}/></Link>,
      children: <Row justify="space-around" align="middle">
        <Col>
          <h2>Силач (эвакуатор - трал)</h2>
          <ul style={{marginBottom: 40, paddingLeft: 15}}>
            <li>Грузоподъемность – до 12т</li>
            <li>Длина и ширина – 7.30м, 2.55м</li>
            <li>Лебедка – до 12т</li>
            <li>Вылет стрелы – 8.5м</li>
            <li>Грузоподъемность КМУ – 6т</li>
            <li>Корзина – двухместная</li>
          </ul>
        </Col>
        <Col>
          <a href={'https://api.whatsapp.com/send?phone=77074393903'}
             className={'button'}><WhatsAppOutlined/> Арендовать</a>
        </Col>
      </Row>,
    },
    {
      key: 'tow-truck-tightener',
      label: <Link to={"/tow-truck-tightener"}><img src={tech3} alt={''} className={'tab-img'}/></Link>,
      children: <Row justify="space-around" align="middle">
        <Col>
          <h2>Крепыш</h2>
          <ul style={{marginBottom: 40, paddingLeft: 15}}>
            <li>Вылет стрелы – 16м</li>
            <li>Корзина – с функцией поворота на 360 градусов</li>
            <li>Эвакуатор затяжник – легковой и грузовой до 12т</li>
            <li>Грузоподъемность КМУ – 3т, 6т, 12т</li>
          </ul>
        </Col>
        <Col>
          <a href={'https://api.whatsapp.com/send?phone=77074393903'}
             className={'button'}><WhatsAppOutlined/> Арендовать</a>
        </Col>
      </Row>,
    },
  ];

  useEffect(() => {

    const script = document.createElement("script");
    script.src = "https://apps.elfsight.com/p/platform.js";
    script.async = true;
    document.body.appendChild(script);

    setInterval(() => {
      let leftSection = document.getElementsByClassName('eapps-link');
      if (leftSection.length > 0) {
        leftSection = leftSection[0];
        leftSection.parentNode.removeChild(leftSection);
      }
    }, 1000)
  }, [])

  useEffect(() => {
    console.log(page)
    if (page) setTab(page); else setTab('front-loader');
  }, [page])

  return <Layout>
    <Header>
      <div className={'content'}>
        <Row>
          <Col flex={'auto'}>
            <a href="https://vikor.kz">vikor.kz</a>
          </Col>
          <Col flex={'auto'} style={{textAlign: 'right'}}>
            <Space size={20}>
              <span><MailOutlined/> <a href="mailto::info@vikor.kz">info@vikor.kz</a></span>
              <span><WhatsAppOutlined/> <a href="https://api.whatsapp.com/send?phone=77074393903">+7 707 439 3903</a></span>
            </Space>
          </Col>
        </Row>
      </div>
    </Header>
    <Content>
      <div className={'content'}>
        <div className={'logo-block'}>
          <div className={'logo-line'}/>
          <div className={'logo-content'}>
            <img src={logo} alt={''}/>
            <p>Аренда спецтехники</p>
          </div>
        </div>

        <Tabs activeKey={tab} centered items={items}/>

        <Divider />

        <h2 style={{textAlign: 'center', color: 'rgba(255, 255, 255, 0.85)'}}>Мы за работой</h2>

        <div className="elfsight-app-f5feb33a-627c-4857-b401-a1900b283cef"/>

        <Divider />

        <p style={{
          textAlign: 'center',
          color: '#999',
          fontSize: 12,
          marginBottom: 25
        }}>© 2017-{moment().format('YYYY')} <Link to={'/'} style={{color:'#fecd0f'}}>Spectruck</Link> - Аренда спецтехники</p>

      </div>
    </Content>

  </Layout>
}

function App() {

  let element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/front-loader",
      element: <Home page={'front-loader'}/>,
    },
    {
      path: "/tow-truck-trawl",
      element: <Home page={'tow-truck-trawl'}/>,
    },
    {
      path: "/tow-truck-tightener",
      element: <Home page={'tow-truck-tightener'}/>,
    },
    { path: "*", element: <NoMatch /> },
  ]);

  return <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        colorPrimary: '#161616',
      },
      components: {
        header: {
          background: '#fff'
        }
      }
    }}
  >
    {element}
  </ConfigProvider>
}

export default App;